import React, { useEffect, useMemo, useState, } from 'react'
import {
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
  ColumnDef,
  Column,
  ColumnFiltersState,
  RowData,
  flexRender,
  getFilteredRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import Tooltip from 'components/atoms/Tooltip/Tooltip';
import Icon from 'components/atoms/Icon/Icon';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { InterfacePixelLeadData, getAllPixelLeads, handleRevealContactByID } from 'store/slices/insights';
import Loading from 'components/atoms/Loading/Loading';
import { showDate } from 'utils/workWithData';
import PixelRow from './PulseRow';
import InputDebounce from 'components/atoms/InputDebounce/InputDebounce';
import EntryFormatter from 'utils/EntryFormatter';
import Button from 'components/atoms/Button/Button';
declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'range' | 'select' | 'none',
    size?: string,
}}
const isMobile = window.innerWidth <= 810
const PulseBody: React.FC<{
  searchPeople: string | number, setSearchPeople: React.Dispatch<React.SetStateAction<string | number>>,
  searchLinkedin: any,
  filterJobTitle: any[],
  filterCompany: any[],
  filterIndustry: any[],
  filterLocationPerson: any[],
  filterLocationCompany: any[],
  filterGender: any[],
  filterSeniority: any[],
  filterDepartment: any[],
  filterCompanyHeadcount: any[],
  filterIncomeRange: any[],
  filterCompanyRevenue: any[],
}> = ({ 
  searchPeople, setSearchPeople,
  searchLinkedin,
  filterLocationPerson, filterLocationCompany,
  filterIncomeRange, filterGender, filterSeniority, filterDepartment, filterJobTitle, filterIndustry,
  filterCompany, filterCompanyHeadcount, filterCompanyRevenue,
}) => {
  const dispatch = useAppDispatch();
  const { data: leads } = useAppSelector((state) => state.insights.leads);
  const [triggerFetch, setTriggerFetch] = useState<number>(0);
  
  const [isSearching, setIsSearching] = useState(false)
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const data = useMemo(() => leads, [leads])

  const fetchResults = async () => {
    setIsSearching(true)
    await dispatch(getAllPixelLeads({
      // page: 1, 
      // size: pageSize, 
      // search_name: searchPeople,
      locations_person: filterLocationPerson.length > 0 ? JSON.stringify(filterLocationPerson.map(i => i.value)) : ``,
      search_linkedin: searchLinkedin,
      job_titles: filterJobTitle.length > 0 ? JSON.stringify(filterJobTitle.map(i => i.value)) : ``,
      companies: filterCompany.length > 0 ? JSON.stringify(filterCompany.map(i => i.value)) : ``,
      // company_headcounts: filterCompanyHeadcount.length > 0 ? JSON.stringify(filterCompanyHeadcount.map(i => i.value)) : ``,
      // company_revenues: filterCompanyRevenue.length > 0 ? JSON.stringify(filterCompanyRevenue.map(i => i.value)) : ``,
      // locations_company: filterLocationCompany.length > 0 ? JSON.stringify(filterLocationCompany.map(i => i.value)) : ``,
      // industries: filterIndustry.length > 0 ? JSON.stringify(filterIndustry.map(i => i.value)) : ``, 
      // genders: filterGender.length > 0 ? JSON.stringify(filterGender.map(i => i.value)) : ``,
      // seniorities: filterSeniority.length > 0 ? JSON.stringify(filterSeniority.map(i => i.value)) : ``,
      // departments: filterDepartment.length > 0 ? JSON.stringify(filterDepartment.map(i => i.value)) : ``,
      // income_ranges: filterIncomeRange.length > 0 ? JSON.stringify(filterIncomeRange.map(i => i.value)) : ``,
    }))
    setIsSearching(false)
  }
  const columns = React.useMemo<ColumnDef<InterfacePixelLeadData>[]>(() => 
  isMobile ? [
    { accessorKey: "leadName",
      header: () => "Lead",
      meta: { size: "auto" },
      cell: ({ row, getValue }) => {
        const value: any = getValue();
        let dataSecondary: string | null = null;
        // if (row.original.email) dataSecondary = row.original.email;
        return <div className="flex items-center gap-3"><div className="text-left inline-grid">
          <div className="text-base truncate text-white-500">{value}</div>
          {dataSecondary && <div className="text-sm text-gray-400 shadow mt-1 truncate">{dataSecondary}</div>}
        </div></div>
    }}]
  : [
    { accessorKey: "leadScore", header: "Score", meta: { size: "9%" }, enableColumnFilter: false, },
    { accessorKey: "leadName", header: () => "Lead", meta: { size: "auto" },
      cell: ({ row, getValue }) => {
        const value: any = getValue();
        let dataSecondary: string | null = null;
        // if (row.original.email) dataSecondary = row.original.email;
        return <div className="flex items-center gap-3"><div className="text-left inline-grid">
          <div className="text-base truncate text-white-500">{value}</div>
          {dataSecondary && <div className="text-sm text-gray-400 shadow mt-1 truncate">{dataSecondary}</div>}
        </div></div>
      }
    },
    {   accessorKey: "orgName", header: () => "Company", meta: { size: "15%" },
        cell: ({ row, getValue, }) => {
          const value: any = getValue();
          return <div className="text-base truncate">{value}</div>
    }},
    {   accessorKey: "visits", header: "Visits", meta: { filterVariant: 'none', size: '8%' }, },
    {   accessorKey: "pageViews", header: () => "Page Views", meta: { filterVariant: 'none', size: '8%' }, },
    {   accessorKey: "duration", header: "Duration", meta: { filterVariant: 'none', size: '8%' }, },
    {   accessorKey: "visitLast", header: () => "Last seen",
        cell: ({ row, getValue }) => {
          const value = getValue() as string;
          return <div className='relative flex items-center justify-center text-sm'>{showDate(value)}</div>
        },
        meta: { filterVariant: 'none', size: '13%' },
    },
    { accessorKey: "isContactRevealed",
      header: () => null,
      meta: { size: "13%" },
      cell: ({ row, getValue }) => {
        const isContactRevealed: any = getValue();
        if (!isContactRevealed) {
          return <div className="flex justify-center">
            <Button
              iconName="credits" iconSize={15} size="xs"
              onClick={(e: any) => {
                e.stopPropagation();
                dispatch(handleRevealContactByID({ leadId: row.original.id }));
              }}
            >Reveal Contact</Button>
          </div>
        }
      },
    },
  ], [])
  const table = useReactTable({
    data, columns, filterFns: {},
    state: { columnFilters, expanded },
    initialState: { sorting: [{ id: "visitLast", desc: true }], pagination: { pageIndex: 0, pageSize: 25 } },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    paginateExpandedRows: false,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
  })
  useEffect(() => {
    let debounceTimer: NodeJS.Timeout;
    debounceTimer = setTimeout(() => { setTriggerFetch(prev => prev + 1) }, 100);
    return () => clearTimeout(debounceTimer);
  }, [searchPeople, searchLinkedin, filterJobTitle, filterCompany, filterIndustry, filterLocationPerson, filterLocationCompany, filterGender, filterIncomeRange, filterSeniority, filterDepartment, filterCompanyHeadcount, filterCompanyRevenue ]) // eslint-disable-line
  useEffect(() => {
    if (triggerFetch <= 1) return
    fetchResults()
  }, [ triggerFetch ]) // eslint-disable-line
  return <>
    <div className='w-full flex py-4 px-3 borderBottom gap-3 justify-end items-center'>
      {/* <InputDebounce onChange={value => setSearchPeople(value) } value={searchPeople} placeholder='Search People' width='w-48' sizeInput='tiny' /> */}
      <div className='text-sm text-gray-400 pr-3'>
        {isSearching
          ? <Loading height="auto" spinnerSize={24} />
          : <><b className='text-white-500'>{EntryFormatter.formatNumber(leads.length)}</b> results found</>
        }
    </div></div>
    <table><thead>
      {table.getHeaderGroups().map(headerGroup => <tr key={headerGroup.id}>
        {headerGroup.headers.map(header => {
          return <th key={header.id} style={{width: header.column.columnDef.meta?.size ?? "auto", textAlign: ["leadName", "orgName"].includes(header.id) ? "left" : "center"}}>
            {!["leadName", "visitLast"].includes(header.id)
              ? flexRender(header.column.columnDef.header, header.getContext())
              : <div className='flex flex-row items-center gap-2'>
                  {header.column.getCanSort() &&
                    <div className={"group cursor-pointer select-none w-max flex"} {...{onClick: header.column.getToggleSortingHandler()}} style={{margin: ["leadName"].includes(header.id) ? "0" : "0 auto"}}>
                      <div className="flex items-center w-auto gap-2 group-hover:bg-paper-600 group-hover:text-white-500 px-2 py-1 rounded-xl transition">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{asc: <Tooltip content="Sort Ascending"><Icon name='sort-up' size={16} /></Tooltip>,
                          desc: <Tooltip content="Sort Descending"><Icon name='sort-down' size={16} /></Tooltip>,
                        }[header.column.getIsSorted() as string] ?? null}
                    </div></div>
                  }
                  {header.column.getCanFilter() ? <Filter column={header.column} /> : null}
            </div>}
          </th>
        })}
      </tr>)}
    </thead></table>
    <div className='h-full overflow-y-auto'><table className={`main${table.getRowCount() === 0 || isSearching ? ' h-full':''}`}><tbody>
      {isSearching
        ? <tr className='noHover'><td><div className="flex flex-col gap-4 items-center justify-center h-full">
          <Loading height="auto" />
          <h1 className="text-sm text-gray-400">Searching...</h1>
        </div></td></tr>
        : <>
          {table.getRowCount() === 0
            // ⭐ Empty State
            ? <tr className='noHover'><td><div className="flex gap-3 items-center justify-center h-full">
                <Icon name="search" size={28} />
                <h1 className="text-base">No matching results were found</h1>
              </div></td></tr>
            : table.getRowModel().rows.map(row => <PixelRow key={row.id} row={row} />)
          }
      </>}
    </tbody></table></div>
    <table>
      <tfoot><tr><td><div className='flex items-center justify-between'>
        <div className="flex items-center gap-1">
          Showing{' '}
          {table.getRowCount() > 0
            ? <strong className='font-bold text-white-500'>
              {(table.getState().pagination.pageIndex * table.getState().pagination.pageSize)+1}
              -
              {(table.getRowCount() < (table.getState().pagination.pageIndex+1) * table.getState().pagination.pageSize)
                ? table.getRowCount() 
                : (table.getState().pagination.pageIndex+1) * table.getState().pagination.pageSize}
            </strong>
            : <strong className='font-bold text-white-500'>0</strong>
          }
          {'of '}
          <strong className='font-bold text-white-500'>{table.getRowCount()}</strong>
        </div>
        <div className='flex sm:flex-row gap-4 sm:gap-6 justify-end'>
          {/* <div className="flex items-center gap-3">
            <div>Results per page:</div>
            <InputDropDown
              triggerTitle={table.getState().pagination.pageSize}
              menuArray={
                [10, 50, 100].map(pageSize => ({
                  title: pageSize,
                  onClick: () => table.setPageSize(pageSize),
                  isSelected: table.getState().pagination.pageSize === pageSize,
                })) as any
            } />
          </div> */}
          <div className="pagination">
            <div className={`${!table.getCanPreviousPage() ? `disabled` : ``}`} onClick={()=>table.setPageIndex(0)}><Icon name="angle-left-double" size={16} /></div>
            <div className={`${!table.getCanPreviousPage() ? `disabled` : ``}`} onClick={()=>table.previousPage()}><Icon name="angle-left" size={16} /></div>
            <div className={`${!table.getCanNextPage() ? `disabled` : ``}`} onClick={()=>table.nextPage()}><Icon name="angle-right" size={16} /></div>
            <div className={`${!table.getCanNextPage() ? `disabled` : ``}`} onClick={()=>table.setPageIndex(table.getPageCount() - 1)}><Icon name="angle-right-double" size={16} /></div>
          </div>
        </div>
      </div></td></tr></tfoot>
    </table>
  </>
}
export default PulseBody
function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue = column.getFilterValue()
  const { filterVariant } = column.columnDef.meta ?? {}
  if (filterVariant === 'none') return <div className="h-5" />
  return filterVariant === 'range' ? (
    <div className="flex space-x-2">
      {/* See faceted column filters example for min max values functionality */}
      <InputDebounce
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={value =>
          column.setFilterValue((old: [number, number]) => [value, old?.[1]])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <InputDebounce
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={value =>
          column.setFilterValue((old: [number, number]) => [old?.[0], value])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : filterVariant === 'select' ? (
    <select onChange={e => column.setFilterValue(e.target.value)} value={columnFilterValue?.toString()}>
      <option value="">All</option>
      <option value="complicated">complicated</option>
      <option value="relationship">relationship</option>
      <option value="single">single</option>
    </select>
  )
  : <InputDebounce sizeInput="tiny" width="auto" placeholder='Search' onChange={value => column.setFilterValue(value)} value={(columnFilterValue ?? '') as string} />
}