import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import Icon from "components/atoms/Icon/Icon";
import InputSelect from "components/atoms/InputSelect/InputSelect";
import { OPTIONS_COMPANY, OPTIONS_COMPANY_HEADCOUNT, OPTIONS_DEPARTMENT, OPTIONS_GENDER, OPTIONS_INDUSTRY,
  OPTIONS_JOBTITLE, OPTIONS_LOCATION, OPTIONS_SENIORITY } from "./SearchMainHelper";
import Button from "components/atoms/Button/Button";
import InputField from "components/atoms/InputField/InputField";
import Tooltip from "components/atoms/Tooltip/Tooltip";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setTriggerApplyFilters } from "store/slices/ui.slice";
const SearchMainFilters: FC<{ searchPeople: string | number;
  setSearchPeople: Dispatch<SetStateAction<string | number>>; searchLinkedin: any;
  setSearchLinkedin: Dispatch<SetStateAction<any>>; filterJobTitle: any[];
  setFilterJobTitle: Dispatch<SetStateAction<any[]>>; filterCompany: any[];
  setFilterCompany: Dispatch<SetStateAction<any[]>>; filterIndustry: any[];
  setFilterIndustry: Dispatch<SetStateAction<any[]>>; filterLocationPerson: any[];
  setFilterLocationPerson: Dispatch<SetStateAction<any[]>>; filterGender: any[];
  setFilterGender: Dispatch<SetStateAction<any[]>>; filterSeniority: any[];
  setFilterSeniority: Dispatch<SetStateAction<any[]>>; filterDepartment: any[];
  setFilterDepartment: Dispatch<SetStateAction<any[]>>; filterLocationCompany: any[];
  setFilterLocationCompany: Dispatch<SetStateAction<any[]>>; filterCompanyHeadcount: any[];
  setFilterCompanyHeadcount: Dispatch<SetStateAction<any[]>>; filterIncomeRange: any[];
  setFilterIncomeRange: Dispatch<SetStateAction<any[]>>; filterCompanyRevenue: any[];
  setFilterCompanyRevenue: Dispatch<SetStateAction<any[]>>; fetchResults: () => Promise<void>; isSearching: boolean;
  isAppliedFilter: boolean; setIsAppliedFilter: (newVal: boolean) => void; setPageCurrent: (newVal: number) => void;
  pageCurrent: number }> = ({ searchPeople, setSearchPeople, searchLinkedin, setSearchLinkedin, filterGender,
  setFilterGender, filterJobTitle, setFilterJobTitle, filterCompany, setFilterCompany, filterIndustry,
  setFilterIndustry, filterLocationPerson, setFilterLocationPerson, filterLocationCompany, setFilterLocationCompany,
  filterSeniority, setFilterSeniority, filterDepartment, setFilterDepartment, filterCompanyHeadcount,
  setFilterCompanyHeadcount, fetchResults, isSearching, isAppliedFilter, setIsAppliedFilter, setPageCurrent,
  pageCurrent }) => {
  const dispatch = useAppDispatch();
  const triggerApplyFilters = useAppSelector((state) => state.ui.triggerApplyFilters);
  const filterDeps = [
    searchPeople,
    searchLinkedin,
    filterJobTitle,
    filterCompany,
    filterIndustry,
    filterLocationPerson,
    filterGender,
    filterSeniority,
    filterDepartment,
    filterLocationCompany,
    filterCompanyHeadcount
  ];
  const isDisabled = useMemo(() => {
    return filterDeps.every(dep => {
      const value = dep?.toString();
      return value.length === 0;
    });
  }, filterDeps);
  const resetFilters = useCallback(() => {
    setSearchPeople("");
    setSearchLinkedin("");
    setFilterJobTitle([]);
    setFilterCompany([]);
    setFilterIndustry([]);
    setFilterLocationPerson([]);
    setFilterGender([]);
    setFilterSeniority([]);
    setFilterDepartment([]);
    setFilterLocationCompany([]);
    setFilterCompanyHeadcount([]);
  }, filterDeps);
  const applyFilters = useCallback(() => {
    setIsAppliedFilter(true);
    setPageCurrent(1);
    if (pageCurrent === 1) fetchResults();
  }, filterDeps);

  useEffect(() => {
    if (isDisabled && isAppliedFilter) {
      fetchResults();
      setIsAppliedFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, isAppliedFilter, filterDeps]);

  useEffect(() => {
    // when the value is updated from within another component's scope
    if(searchPeople && triggerApplyFilters) {
      applyFilters();
      dispatch(setTriggerApplyFilters(false));
    }
  }, [searchPeople, triggerApplyFilters])

  return (
    <>
      <div className="flex flex-row justify-between gap-2 p-5 w-full">
        <Tooltip isFullWidth content={isDisabled ? "Kindly select atleast on filter" : ""}>
          <Button
            disabled={isDisabled}
            onClick={() => applyFilters()}
            iconName="filter" 
            iconSize={18}
            size="sm" className="w-full"
            isProcessing={isSearching}
            isProcessingTitle="Fetching..."
          >
            Apply Filters
          </Button>
        </Tooltip>
        <Button
          disabled={isDisabled}
          size="sm"
          onClick={() => resetFilters()}
          className="w-fit"
          >
            <Icon name="close" size={12} />
        </Button>
      </div>
      <ul id="menu-parent" className="SearchMainFilter">
        <FilterItem label="Name" icon="user-check" type="input" state={searchPeople} setState={setSearchPeople}
          isActive={searchPeople.toString().length > 0} placeholder="Search People" />
        <FilterItem label="Person Location" icon="user-location" state={filterLocationPerson}
          setState={setFilterLocationPerson} isActive={filterLocationPerson.length > 0} options={OPTIONS_LOCATION}
          placeholder="Enter locations" />
        <FilterItem label="Linkedin" icon="linkedin" type="input" state={searchLinkedin}
          setState={setSearchLinkedin} isActive={searchLinkedin.toString().length > 0}
          placeholder="Enter a LinkedIn URL" />
        {/* PDL doesn't provide income range 
        <FilterItem label="Income Range" icon="income"
          state={filterIncomeRange}
          setState={setFilterIncomeRange}
          isActive={filterIncomeRange.length > 0}
          options={OPTIONS_INCOME_RANGE}
          placeholder="Choose an income range"
          isSearchable={false}
        /> */}
        <FilterItem label="Job Title" icon="job" state={filterJobTitle} setState={setFilterJobTitle}
          isActive={filterJobTitle.length > 0} options={OPTIONS_JOBTITLE} placeholder="Enter job titles" />
        <FilterItem label="Seniority" icon="seniority" state={filterSeniority} setState={setFilterSeniority}
          isActive={filterSeniority.length > 0} options={OPTIONS_SENIORITY} placeholder="Enter seniorities" />
        <FilterItem label="Department" icon="category" state={filterDepartment} setState={setFilterDepartment}
          isActive={filterDepartment.length > 0} options={OPTIONS_DEPARTMENT} menuPlacement="top"
          placeholder="Enter departments" />
        <FilterItem label="Industry" icon="industry" state={filterIndustry} setState={setFilterIndustry}
          isActive={filterIndustry.length > 0} options={OPTIONS_INDUSTRY} menuPlacement="top"
          placeholder="Enter industries" />
        <FilterItem label="Company Name" icon="company" state={filterCompany} setState={setFilterCompany}
          isActive={filterCompany.length > 0} options={OPTIONS_COMPANY} menuPlacement="top"
          placeholder="Enter companies" />
        <FilterItem label="Company Location" icon="location" state={filterLocationCompany}
          setState={setFilterLocationCompany} isActive={filterLocationCompany.length > 0} options={OPTIONS_LOCATION}
          menuPlacement="top" placeholder="Enter locations" />
        {/* PDL doesn't provide company revnue 
      <FilterItem label="Company Revenue" icon="revenue"
        state={filterCompanyRevenue} setState={setFilterCompanyRevenue}
        isActive={filterCompanyRevenue.length > 0}
        placeholder="Choose revenue"
        isSearchable={false}
        options={OPTIONS_COMPANY_REVENUE}
      /> */}
        <FilterItem label="Company Headcount" icon="people" state={filterCompanyHeadcount}
          setState={setFilterCompanyHeadcount} isActive={filterCompanyHeadcount.length > 0}
          placeholder="Choose headcount" isSearchable={false} menuPlacement="top"
          options={OPTIONS_COMPANY_HEADCOUNT} />
        <FilterItem label="Gender" icon="gender" state={filterGender} setState={setFilterGender}
          isActive={filterGender.length > 0} options={OPTIONS_GENDER} placeholder="Choose gender"
          isSearchable={false} />
      </ul>
    </>
  );
};
export default SearchMainFilters;
const FilterItem: FC<any> = ({ type = "select", state, setState, label, labelInput, icon, isActive, options, placeholder, isSearchable = true, isMulti = true, menuPlacement = "auto" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState<string | number>("");
  useEffect(() => {
    if (state === searchInput || type !== "input") return;
    setSearchInput(state as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  return (
    <li
      className={`${isActive ? "is_active" : ""} ${isOpen ? "is_open" : ""}`}
      onClick={() => {
        if (!isOpen) setIsOpen(!isOpen);
      }}
    >
      <div className="content-title flex items-center justify-evenly gap-2" onClick={() => setIsOpen(!isOpen)}>
        <div className="w-full flex items-center gap-2">
          <Icon name={icon} size={20} />
          {label}
        </div>
        {isActive && (
          <Button
            size="xxs"
            onClick={(e: any) => {
              e.stopPropagation();
              setState([]);
            }}
          >
            {type === "input" ? 1 : state?.length}
            <Icon name="close" size={12} />
          </Button>
        )}
        <Icon name={isOpen ? "caret-up" : "caret-down"} size={15} />
      </div>
      {isOpen && (
        <div className="px-[20px] pt-0 pb-4">
          {type === "input" && (
            <form
              className="flex gap-2 items-center"
              onSubmit={(e) => {
                e.preventDefault();
                setState(searchInput);
              }}
            >
              <InputField variant="dark" placeholder={placeholder} size="small" value={state} onChange={(e: any) => setState(e.target.value)} />
              {/* <Button
                iconName="search"
                iconSize={16}
                size="xs"
                onClick={() => setSearchInput(searchInput)}
              >
                Search
              </Button> */}
            </form>
            // <InputDebounce
            //   value={state}
            //   variant="dark"
            //   sizeInput="small"
            //   onChange={(value) => setState(value)}
            //   placeholder={placeholder}
            // />
          )}
          {type === "select" && (
            <InputSelect id={label === "Seniority" ? "seniority-select" : ""} label={labelInput} options={options}
              state={state} setState={setState} placeholder={placeholder} isSearchable={isSearchable}
              isMulti={isMulti} menuPlacement={menuPlacement} />
          )}
        </div>
      )}
    </li>
  );
};
